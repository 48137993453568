import React, { createContext, useContext } from 'react'

type EditStore = {
  toggleEditStoreModal: () => void
}

export const EditStoreContext = createContext<EditStore>({
  toggleEditStoreModal: () => null
})

export const useEditStore = () => useContext(EditStoreContext)
