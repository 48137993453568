import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import styles from './horizontalBar.module.scss'
import IHamburgerMenuProps from './interfaces/hamburgerMenu'
import Calculators from '../Calculators'

const HamburgerMenu: FunctionComponent<IHamburgerMenuProps> = ({
  logoText,
  sleepExpertName,
  onUserGuideClick,
  calculatorControls,
  onEditStoreClick,
  onMergeGuideClick,
  sleepExpertStore
}) => {
  return (
    <div className={styles.menuWrapper}>
      <div className={styles.menuBackdrop} />
      <div className={styles.menuContainer} id="navigation_menu">
        <div className={styles.menuHeader}>
          <div className={styles.title}>{logoText}</div>
          <div className={styles.subTitle}>{sleepExpertName}</div>
        </div>
        <ul className={styles.menuList}>
          <Calculators
            displaySynchronyCalculatorBrandedStore={calculatorControls?.displaySynchronyCalculatorBrandedStore}
            displayProgressiveCalculator={calculatorControls?.displayProgressiveCalculator}
          />
          <li>
            <button
              onClick={onUserGuideClick}
              type="button"
              className={styles.plainTextButton}
              id="hamburger_menu_user_guide_button"
            >
              User Guide
            </button>
          </li>
          <li>
            <button
              onClick={onMergeGuideClick}
              type="button"
              className={styles.plainTextButton}
              id="hamburger_menu_merge_process_explained_button"
            >
              Merge Process Explained
            </button>
          </li>
          <li>
            <button
              className={clsx(styles.plainTextButton, { [styles.disabledMenuItem]: !onEditStoreClick })}
              onClick={onEditStoreClick}
              type="button"
              id="hamburger_menu_edit_store_button"
            >
              Edit Store: {sleepExpertStore}
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default HamburgerMenu
