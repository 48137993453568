import { useSetAtom } from 'jotai'
import { RESET, useResetAtom } from 'jotai/utils'
import { useCallback } from 'react'
import { initialLeadAtomBase, leadAtomBase, noteDetailsAtom, savedAtom, tabAtom } from '../../atoms'

const useResetRestAtoms = () => {
  const resetLead = useResetAtom(leadAtomBase)
  const resetInitialLead = useResetAtom(initialLeadAtomBase)
  const setNoteDetails = useSetAtom(noteDetailsAtom)
  const setSaved = useSetAtom(savedAtom)
  const setTab = useSetAtom(tabAtom)

  const resetAtoms = useCallback(() => {
    resetLead()
    resetInitialLead()
    setNoteDetails(RESET)
    setSaved(RESET)
    setTab(RESET)
  }, [resetLead, resetInitialLead, setNoteDetails, setSaved, setTab])

  return {
    resetAtoms
  }
}

export default useResetRestAtoms
