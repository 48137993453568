import React, { FunctionComponent } from 'react'
import clsx from 'clsx'
import Typography from '../Typography'
import ClearButton from './ClearButton'
import { AdjustableBase, Product } from './interfaces'
import BaseFavoriteItem from './BaseFavoriteItem'
import ProductGridItem from './ProductGridItem'
import styles from './styles/favorites.module.scss'
import { IFavoritesContext } from '../FavoritesProvider/interfaces'
import { UtagEventCategory } from '../FavoritesProvider/interfaces/analytics'
import useSendEventCategoryOnLoadAnalytics from '../FavoritesProvider/hooks/useSendEventCategoryOnLoadAnalytics'
import Loading from '../Loading'

const Favorites: FunctionComponent<IFavoritesContext> = ({ ...rest }) => {
  const {
    isDreamHub,
    favorites,
    isProductsLoading,
    analyticsProps,
    removeByTypeName,
    clearFavorites,
    getMattressesByTypeName,
    getAdjustableBasesByTypeName,
    getFrameByTypeName
  } = rest

  const { sendEventCategoryOnLoad } = analyticsProps ?? {}

  useSendEventCategoryOnLoadAnalytics(UtagEventCategory.Favorites, sendEventCategoryOnLoad)

  console.log('[Favorites] - Favorites UI', { isDreamHub, favorites })

  const {
    dreamhubClearButton,
    dreamhubEmptyFavoritesTitle,
    dreamhubFavoritesWrapper,
    emptyFavoritesText,
    emptyFavoritesTitle,
    emptyFavoritesTitleWrapper,
    favoritesClearButton,
    favoritesGridTitle,
    favoritesGridTitleWrapper,
    favoritesGridWrapper,
    favoritesTitle,
    favoritesTitleWrapper,
    favoritesWrapper,
    favoritesLoader,
    favoritesWrapperLoading
  } = styles

  const mattresses = getMattressesByTypeName('Mattress')
  const adjustableBases = getAdjustableBasesByTypeName('AdjustableBase')
  const frames = getFrameByTypeName('Frame')

  return isProductsLoading ? (
    <Loading className={favoritesLoader} size="28px" />
  ) : (
    <div
      className={clsx(
        isDreamHub && dreamhubFavoritesWrapper,
        favoritesWrapper,
        isProductsLoading && favoritesWrapperLoading
      )}
    >
      {!!favorites?.length && (
        <div className={favoritesTitleWrapper}>
          <Typography className={favoritesTitle} variant="h4">
            Favorites
          </Typography>
          <ClearButton
            className={clsx(favoritesClearButton, isDreamHub && dreamhubClearButton)}
            onClick={() => clearFavorites()}
          />
        </div>
      )}

      {!!mattresses && mattresses.length > 0 && (
        <>
          <div className={favoritesGridTitleWrapper}>
            <Typography
              className={`favorites-mattresses-title compare-products-title ${favoritesGridTitle}`} // some testing classes
              variant="h5"
            >
              Mattresses
            </Typography>
            <ClearButton
              className={clsx(
                'favorites-mattresses-clear-button',
                favoritesClearButton,
                isDreamHub && dreamhubClearButton
              )}
              onClick={() => removeByTypeName('Mattress')}
            />
          </div>
          <div className={clsx(isDreamHub && styles.dreamHubFavorites, favoritesGridWrapper)}>
            {mattresses.map((product: Product) => (
              <div className={clsx(isDreamHub ? styles.dreamHubTiles : styles.XrayTiles)} key={product.id}>
                <ProductGridItem itemClassName="product-favorite-item" product={product} {...rest} />
              </div>
            ))}
          </div>
        </>
      )}

      {!!frames && frames.length > 0 && (
        <>
          <div className={favoritesGridTitleWrapper}>
            <Typography
              className={`favorites-mattresses-title compare-products-title ${favoritesGridTitle}`} // some testing classes
              variant="h5"
            >
              Frames
            </Typography>
            <ClearButton
              className={clsx(
                'favorites-mattresses-clear-button',
                favoritesClearButton,
                isDreamHub && dreamhubClearButton
              )}
              onClick={() => removeByTypeName('Frame')}
            />
          </div>
          <div className={clsx(isDreamHub && styles.dreamHubFavorites, favoritesGridWrapper)}>
            {frames.map((product: Product) => (
              <div className={clsx(isDreamHub ? styles.dreamHubTiles : styles.XrayTiles)} key={product.id}>
                <ProductGridItem itemClassName="product-favorite-item" product={product} {...rest} />
              </div>
            ))}
          </div>
        </>
      )}
      {!!adjustableBases && adjustableBases.length > 0 && (
        <>
          <div className={favoritesGridTitleWrapper}>
            <Typography className={`favorites-bases-title compare-products-title ${favoritesGridTitle}`} variant="h5">
              Adjustable Bases
            </Typography>
            <ClearButton
              className={clsx('favorites-bases-clear-button', favoritesClearButton, isDreamHub && dreamhubClearButton)}
              onClick={() => removeByTypeName('AdjustableBase')}
            />
          </div>
          <div className={clsx(isDreamHub && styles.dreamHubFavorites, favoritesGridWrapper)}>
            {adjustableBases.map((product: Product) => (
              <div className={clsx(isDreamHub ? styles.dreamHubTiles : 'col-md-3', 'col-12')} key={product.id}>
                <ProductGridItem itemClassName="product-favorite-item" product={product} {...rest}>
                  <BaseFavoriteItem product={product as AdjustableBase} />
                </ProductGridItem>
              </div>
            ))}
          </div>
        </>
      )}

      {!favorites?.length && (
        <div className={emptyFavoritesTitleWrapper}>
          <Typography
            className={clsx(emptyFavoritesTitle, isDreamHub && dreamhubEmptyFavoritesTitle)}
            variant="paragraph"
          >
            Favorite Products
          </Typography>
          <Typography className={emptyFavoritesText} variant="paragraph">
            No products selected
          </Typography>
        </div>
      )}
    </div>
  )
}

export default Favorites
