import { Product } from '../interfaces'

/**
 * Mock Favorites session payload.
 */
export const mockMattress: Product = {
  id: '143691P',
  productId: '143691P',
  recId: '5637350826',
  name: 'Black® C-Class 13.75" Medium Mattress',
  url: 'https://www.mattressfirm.com/black-c-class-1375-medium-mattress/5637350826.p',
  brandId: 'beautyrest',
  thumbnail:
    'https://images-us-prod.cms.dynamics365commerce.ms/cms/api/cncgmclkfv/imageFileData/search?fileName=143691P-xray-thumbnail.png',
  archived: false,
  features: [
    { name: 'Cooling', score: 7 },
    { name: 'Craftsmanship', score: 8 },
    { name: 'Durability', score: 8 },
    { name: 'Motion Isolation', score: 8 },
    { name: 'Pressure Relief', score: 8 },
    { name: 'Support', score: 8 }
  ],
  hotspots: [
    {
      id: 1,
      title: 'COOLING',
      description:
        'BLACKICE™ With Plant-Based Cooling \\n Plant-based phase change material (PCM) at the surface absorbs and dissipates heat to deliver 18% more cooling power for refreshing comfort.',
      image: 'Cooling.png'
    },
    {
      id: 2,
      title: 'PRESSURE RELIEF',
      description:
        'SurfaceTouch™ Gel Memory Foam, Medium Comfort & Self-Response™ Latex \\n Experience immediate pressure relief for your back, shoulders and hips with personalized support from comforting latex and two layers of advanced memory foam.',
      image: 'Pressure Relief.png'
    },
    {
      id: 3,
      title: 'BREATHABILITY',
      description:
        'Cashmere, Alpaca & Silk \\n A luxurious layer of fine, highly durable materials to help ensure consistent air flow and breathability for refreshing sleep.',
      image: 'Breathability.png'
    },
    {
      id: 4,
      title: 'SUPPORT',
      description:
        'Zoned T3 Pocketed Coil® Technology \\n Patented coils provide 21% more targeted support where you need it most. The support system also reduces motion transfer so you and your partner sleep undisturbed.',
      image: 'Support.png'
    }
  ],
  __typename: 'Mattress',
  brand: { id: 'beautyrest', name: 'BEAUTYREST' },
  model: {
    productId: '143691P',
    url: 'https://stproductxraydev.blob.core.windows.net/imgs/models-gz/2C_Beautyrest C Medium Black - 2022-12-14/Model/2C BeautyRest C Medium Black-transformed.glb',
    scale: 2.2,
    rotation: [0, 0, 0],
    cutaways: [
      { layers: ['9_Mattress_Base', '10_Ground_Base_and_Plate'], offset: [] },
      { layers: ['8_Energy_Foam'], offset: [] },
      {
        layers: ['7_Side_Foam', '7_Coil_001_Array', '7_Coil_002_Array', '7_Coil_003_Array'],
        offset: [],
        hotSpot: {
          id: 4,
          offset: [0, 0.7, 0],
          info: {
            id: 4,
            title: 'SUPPORT',
            description:
              'Zoned T3 Pocketed Coil® Technology \\n Patented coils provide 21% more targeted support where you need it most. The support system also reduces motion transfer so you and your partner sleep undisturbed.',
            image: 'Support.png'
          }
        }
      },
      { layers: ['5_Gel_Touch_Foam'], offset: [] },
      {
        layers: ['4_Nautral_Alpaca'],
        offset: [],
        hotSpot: {
          id: 3,
          offset: [0, 0.85, 0],
          info: {
            id: 3,
            title: 'BREATHABILITY',
            description:
              'Cashmere, Alpaca & Silk \\n A luxurious layer of fine, highly durable materials to help ensure consistent air flow and breathability for refreshing sleep.',
            image: 'Breathability.png'
          }
        }
      },
      {
        layers: ['3_Self_Response_Latex'],
        offset: [],
        hotSpot: {
          id: 2,
          offset: [0, 0.9, 0],
          info: {
            id: 2,
            title: 'PRESSURE RELIEF',
            description:
              'SurfaceTouch™ Gel Memory Foam, Medium Comfort & Self-Response™ Latex \\n Experience immediate pressure relief for your back, shoulders and hips with personalized support from comforting latex and two layers of advanced memory foam.',
            image: 'Pressure Relief.png'
          }
        }
      },
      { layers: ['5_Firm_Comfort_Foam'], offset: [] },
      { layers: ['1_SurfaceTouch_Gel'], offset: [] },
      {
        layers: ['0_TopCover'],
        offset: [],
        hotSpot: {
          id: 1,
          offset: [0, 1, 0],
          info: {
            id: 1,
            title: 'COOLING',
            description:
              'BLACKICE™ With Plant-Based Cooling \\n Plant-based phase change material (PCM) at the surface absorbs and dissipates heat to deliver 18% more cooling power for refreshing comfort.',
            image: 'Cooling.png'
          }
        }
      }
    ],
    lighting: { ambient: 0.3, spot: 0.9 }
  }
}

export const mockAdjustableBase: Product = {
  version: 1679007700589,
  id: '129168P',
  productId: '129168P',
  recId: '5637147203',
  name: '900 Adjustable Base',
  url: 'https://www.mattressfirm.com/900-adjustable-base/5637147203.p',
  brandId: 'mattress-firm',
  thumbnail:
    'https://images-us-prod.cms.dynamics365commerce.ms/cms/api/cncgmclkfv/imageFileData/search?fileName=129168P-xray-thumbnail.png',
  order: 0,
  features: { head: true, feet: true, lumbar: true, flat: true, zeroGravity: true, lights: true, massage: true },
  dimensions: { width: 59, length: 79, minHeight: 18.25, maxHeight: 43 },
  remoteFunctions: {
    headboardArticulation: true,
    footArticulation: true,
    pillowTilt: true,
    perfectSeat: false,
    furnitureFinish: false,
    underBedLight: 'Multi-Color',
    massage: true,
    automaticSnoreResponse: false
  },
  structure: {
    legs: '9-16" Adjustable',
    zeroClearance: false,
    usbPort: true,
    headboardBracket: 'Available',
    warranty: 5,
    liftCapacity: 850
  },
  __typename: 'AdjustableBase',
  brand: { id: 'mattress-firm', name: 'MATTRESS FIRM', productCategory: 'adjustableBase' },
  model: {
    productId: '129168P',
    url: 'https://stproductxraydev.blob.core.windows.net/imgs/adjustable-bases-models-gz/MF_900_Update_1/MF900.fbx',
    animations: { head: 'armature|Head_Up', feet: 'armature|Feet_Up', lumbar: 'armature|Lumbar_Up' },
    scale: 0.01,
    rotation: [0, 0, 0],
    materials: {
      M_0_3_AO: 'M_0_3_AO.png',
      M_0_3_BaseColor: 'M_0_3_Base_Color.png',
      M_0_3_Metalness: 'M_0_3_Metallic.png',
      M_0_3_Normal: 'M_0_3_Normal.png',
      M_0_3_Roughness: 'M_0_3_Roughness.png'
    }
  }
}
