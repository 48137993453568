import React, { FunctionComponent, useMemo, useRef } from 'react'
import clsx from 'clsx'
import { capitalize } from 'lodash'
import LinesEllipsis from 'react-lines-ellipsis'
import BrandLogo from '../BrandLogo'
import Darc from '../Darc'
import Typography from '../Typography'
import { getThumbnailSrc, assetsPath } from './utils/products'
import FavoriteCircleButton from './FavoriteCircleButton'
import DiscontinuedTag from './DiscontinuedTag'
import { IProductGridItem, PdpProduct } from './interfaces'
import styles from './styles/productGrid.module.scss'
import { IFavoritesContext } from '../FavoritesProvider/interfaces'
import { resetEventCategory } from '../FavoritesProvider/utils'
import { UtagEventAction, UtagEventCategory } from '../FavoritesProvider/interfaces/analytics'
import SecondaryInformation from '../ProductDetails/SecondaryInformation'
import useRatingAndCounts from './hooks/useRatingAndCounts'
import { DEFAULT_ANALYTICS_PROPS } from '../FavoritesProvider/consts'

const ProductGridItem: FunctionComponent<IProductGridItem & IFavoritesContext> = ({
  product,
  children,
  itemClassName = 'product-grid-item',
  ...rest
}) => {
  const { __typename: productTypeName } = product
  const ref = useRef(null)
  const path = assetsPath(product)
  const {
    analyticsProps: { sendDreamHubClickEventWithType } = DEFAULT_ANALYTICS_PROPS,
    isSaved,
    toggleSave,
    compareButton,
    isDreamHub,
    pdpProps: { setSelectedProduct } = {},
    navigationTabProps,
    routeProps
  } = rest

  const showRatingAndCounts = useRatingAndCounts(isDreamHub, product)

  const isFavorites = routeProps?.route.indexOf('/favorites') === 0 || navigationTabProps?.currentTab === 'Favorites'

  const productItemUrl = useMemo(() => {
    const { id } = product
    return routeProps?.getDynamicProductItemUrl?.({ id, query: routeProps?.query, path })
  }, [product, routeProps, path])

  const handleClick = () => {
    const { redirect } = routeProps ?? {}

    redirect?.(`${productItemUrl}`)

    setSelectedProduct?.(product as PdpProduct)

    let productType = ''
    switch (productTypeName) {
      case 'Mattress':
        productType = UtagEventCategory.Mattresses
        break
      case 'Frame':
        productType = UtagEventCategory.Frames
        break

      default:
        productType = UtagEventCategory.Bases
        break
    }

    resetEventCategory?.(productType)

    sendDreamHubClickEventWithType(UtagEventAction.ViewPLP, product, productType)
  }

  const productBrand = capitalize(product.brand?.name)
  const capitalizeProductBrand = productBrand
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
  const capitalizedProductBrand = capitalizeProductBrand.join(' ')

  const {
    archived,
    card,
    cardImgTop,
    discontinued,
    divider,
    dreamHubCard,
    favoritesImgTop,
    imageLink,
    imageWrapper,
    productBrandText,
    productGridImage,
    secondaryInformationStyle
  } = styles

  return (
    <Darc
      ref={ref}
      className={clsx(itemClassName, card, !!product.archived && archived, {
        'dreamHub-radius': isDreamHub,
        [dreamHubCard]: isDreamHub
      })}
    >
      {!!product.archived && <DiscontinuedTag className={clsx(`${itemClassName}-discontinued-tag`, discontinued)} />}

      <a tabIndex={0} role="button" className={imageLink} onClick={handleClick} onKeyDown={handleClick}>
        <div className={clsx(`${itemClassName}-image`, isDreamHub && isFavorites ? favoritesImgTop : cardImgTop)}>
          <div className={clsx(imageWrapper, isDreamHub && styles.imageBackground)}>
            <img
              className={clsx(`${itemClassName}-thumbnail`, productGridImage)}
              style={{ pointerEvents: 'none' }}
              src={getThumbnailSrc(product)}
              alt={product.name}
            />
          </div>
        </div>
        <Darc.Body>
          <Darc.Title>
            {isDreamHub ? (
              <Typography className={productBrandText} variant="h6">
                {capitalizedProductBrand}
              </Typography>
            ) : (
              <BrandLogo product={product} />
            )}
          </Darc.Title>
          <Darc.Text tag="div">
            <Typography variant="paragraph" tag="div">
              <LinesEllipsis text={product.name} maxLine={isDreamHub ? '1' : '2'} />
            </Typography>
            <SecondaryInformation
              className={secondaryInformationStyle}
              totalReviewCount={product?.totalReviewCount || 0}
              averageRating={product?.averageRating || 0}
              showRatingAndCounts={showRatingAndCounts}
            />
            {!!children && (
              <>
                <hr className={divider} />
                {children}
              </>
            )}
          </Darc.Text>
        </Darc.Body>
      </a>

      <div className={styles.favoriteCompareContainer}>
        {!isFavorites && compareButton}
        <FavoriteCircleButton
          product={product}
          isSaved={isSaved}
          toggleSave={toggleSave}
          isDreamHub={isDreamHub}
          sendDreamHubClickEventWithType={sendDreamHubClickEventWithType}
          utagEventCategory={productTypeName === 'Mattress' ? UtagEventCategory.Mattresses : UtagEventCategory.Bases}
        />
      </div>
    </Darc>
  )
}

export default ProductGridItem
