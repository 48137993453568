import React, { useCallback, useEffect, useRef } from 'react'
import clsx from 'clsx'
import Fade from '../Fade'
import Portal from '../Portal'
import trapFocus from '../utils/trapFocus'
import styles from './editStoreModal.module.scss'
import IEditStoreModal from './interfaces/editStoreModal'
import Close from '../Icons/Close'
import Loading from '../Loading'
import useEditStoreModal from './hooks/useEditStoreModal'
import { getDeviceType, TABLET_DEVICE } from '../utils/getDeviceType'
import EditStoreSearchBox from '../EditStoreSearchBox'

const EditStoreModal = ({
  onClose,
  getStoresList,
  saveMyStoreInfo,
  modalTitle = 'Change My Store',
  buttonLabel,
  currentStoreId,
  currentStoreName
}: IEditStoreModal) => {
  const portalRef = useRef<HTMLDivElement>(null)
  const modalRefWrapper = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const { sleepExpertInfo, isSaving, isSearchProcessing, storesList } = useEditStoreModal(
    getStoresList,
    saveMyStoreInfo,
    onClose
  )

  const useStoreId = currentStoreId || sleepExpertInfo.storeNumber
  const useStoreName = currentStoreName || sleepExpertInfo.storeName

  const handleKeys = useCallback(
    (e: KeyboardEvent) => {
      const { key } = e

      switch (key) {
        case 'Escape': {
          onClose()
          break
        }
        case 'Tab': {
          trapFocus(e, modalRefWrapper)
          break
        }
        default:
          break
      }
    },
    [onClose]
  )

  useEffect(() => {
    const device = getDeviceType()
    if (!isSearchProcessing && storesList.length && inputRef.current && device === TABLET_DEVICE) {
      inputRef.current.blur()
    }
  }, [isSearchProcessing, storesList.length])

  useEffect(() => {
    document.addEventListener('keydown', handleKeys)
    window.addEventListener('popstate', onClose)
    document.body.style.overflowY = 'hidden'
    return () => {
      document.removeEventListener('keydown', handleKeys)
      window.addEventListener('popstate', onClose)
      document.body.style.overflowY = 'auto'
    }
  }, [handleKeys, onClose])

  return (
    <Portal ref={portalRef}>
      <div>
        <Fade className={styles.backdrop} duration={50} in opacity={0.5} style={{ zIndex: 950 }} onClick={onClose} />
        <div
          className={clsx([styles.storeModalContainer, { [styles.withResults]: storesList.length }])}
          ref={modalRefWrapper}
        >
          <button type="button" className={styles.close} tabIndex={-1} onClick={onClose} id="edit_store_modal_close">
            <Close variant="bold" height={12} width={12} fillColor="#2D2926" />
          </button>
          <div className={styles.header}>
            <p className={styles.title}>{modalTitle}</p>
            <div className={styles.subtitleWrapper}>
              <div className={styles.subtitle}>
                {useStoreId} - {useStoreName}
                {isSaving && (
                  <div className={styles.savingSpinner}>
                    <Loading size="sm" color="#006601" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <EditStoreSearchBox
            onClose={onClose}
            getStoresList={getStoresList}
            saveMyStoreInfo={saveMyStoreInfo}
            buttonLabel={buttonLabel}
          />
        </div>
      </div>
    </Portal>
  )
}

export default EditStoreModal
