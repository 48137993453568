import clsx from 'clsx'
import React, { FunctionComponent, MouseEvent } from 'react'
import INavOption from './interfaces/navOption'
import styles from './styles/navOption.module.scss'

interface Props extends INavOption {
  selected?: boolean
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void
  id?: string
}

const NavOption: FunctionComponent<Props> = ({ destination, icon, title, selected, onClick, id }) => (
  <a
    href={destination}
    className={clsx(styles.navOption, selected && styles.active)}
    onClick={onClick}
    data-testid={`nav-option-${title}`}
    id={id}
  >
    <span className={styles.icon}>{icon}</span>
    <span className={styles.title}>{title}</span>
  </a>
)

export default NavOption
