import { z } from 'zod'
import { statusValues, communicationMethods, stages, mappedStatusValues, sourceLeadMethods } from '../../consts'

export const stageResponse = z.object({
  id: z.string(),
  dueDate: z.string(),
  status: z.string(),
  name: z.enum(stages),
  order: z.number()
})

export const leadDetails = z.object({
  storeNumber: z.string().optional(),
  status: z.enum(statusValues),
  profile: z.string(),
  contactId: z.string(),
  preferredContactMethod: z.enum(communicationMethods).nullable(),
  leadSource: z.enum(sourceLeadMethods).nullish(),
  id: z.string(),
  dueDate: z.string().nullish(),
  mobileNumber: z.string().nullish(),
  homeNumber: z.string().nullish(),
  email: z.string().nullish(),
  assignedToADID: z.string().nullish(),
  assignedToName: z.string().nullish(),
  quoteNumber: z.string().nullish(),
  nextStageName: z.enum(stages).nullish(),
  closedDate: z.string().nullish(),
  stages: z.array(stageResponse),
  customerCode: z.string(),
  DHCompletedBy: z.string().nullish(),
  createdDate: z.string().nullish(),
  reopenDate: z.string().nullish(),
  lastModifiedDate: z.string().nullish(),
  lastModifiedByName: z.string().nullish()
})

export const leadSchemaWithStatus = leadDetails.extend({
  mappedStatus: z.enum(mappedStatusValues)
})

export const leadResponse = leadDetails.extend({
  createdDate: z.string(),
  currentStageName: z.string().nullish(),
  currentStageDueDate: z.string().nullish()
})

export type LeadDetails = z.infer<typeof leadDetails>
export type LeadWithStatus = z.infer<typeof leadSchemaWithStatus>
export type StageResponse = z.infer<typeof stageResponse>
