import clsx from 'clsx'
import React, { FunctionComponent } from 'react'
import Button from '../Button'
import Heart from '../Icons/Heart'
import { DEFAULT_FAVORITE_SVG_HEIGHT, DEFAULT_FAVORITE_SVG_WIDTH } from './consts/favorites'
import { Product } from './interfaces'

import styles from './styles/favoriteCircleButton.module.scss'
import { FavoriteIconProps } from './consts/dreamhub'
import { UtagEventAction, UtagEventCategory } from '../FavoritesProvider/interfaces/analytics'
import { getTaggingIdWithBase } from '../utils/tagging'

export interface IFavoriteCircleButton {
  product: Product
  utagEventCategory: UtagEventCategory | string
  isDreamHub: boolean
  sendDreamHubClickEventWithType: (eventAction: string, product: Product, type?: string) => void
  isSaved: (product: Product) => boolean
  toggleSave: (product: Product) => void
}

const CS_BASE = 'favorite_products'
const getTaggingId = getTaggingIdWithBase(CS_BASE)

const FavoriteCircleButton: FunctionComponent<IFavoriteCircleButton> = ({ product, ...rest }) => {
  const { isSaved, toggleSave, utagEventCategory, isDreamHub, sendDreamHubClickEventWithType } = rest
  const isFavoriteSaved = isSaved(product)
  const toggleFavoriteSave = () => toggleSave(product)

  const btnClasses = clsx(
    isDreamHub && styles.favoriteButton,
    !isFavoriteSaved && isDreamHub && styles.favoriteIdle,
    !isFavoriteSaved && !isDreamHub && styles.favoriteIdleXray,
    isFavoriteSaved && isDreamHub && styles.favoriteDreamHub,
    isFavoriteSaved && !isDreamHub && styles.favoriteXRay
  )

  const favoriteStyle = () => {
    let btnType = ''
    if (isDreamHub) {
      btnType = isFavoriteSaved ? 'primary-blue' : 'tertiary'
    } else {
      btnType = isFavoriteSaved ? 'primary' : 'tertiary'
    }

    return btnType as 'primary' | 'primary-blue' | 'secondary'
  }

  const clickHandler = () => {
    sendDreamHubClickEventWithType?.(
      isSaved(product) ? UtagEventAction.RemoveFromFavorites : UtagEventAction.AddToFavorites,
      product,
      utagEventCategory
    )
    toggleFavoriteSave()
  }

  const { saved, unSaved } = FavoriteIconProps
  const dreamHubActiveProps = isDreamHub ? saved : {}
  const dreamHubProps = isDreamHub ? unSaved : {}
  return (
    <Button
      className={btnClasses}
      btnType={favoriteStyle()}
      size="sm"
      onClick={clickHandler}
      title="Favorite"
      analyticsID={getTaggingId(`favorite_icon_${product.id}`)}
    >
      {isFavoriteSaved ? (
        <Heart
          variation="variant-2"
          fillColor="#fff"
          fillColor2="#fff"
          width={DEFAULT_FAVORITE_SVG_WIDTH}
          height={DEFAULT_FAVORITE_SVG_HEIGHT}
          strokeWidth="1"
          {...dreamHubActiveProps}
        />
      ) : (
        <Heart
          variation="variant-1"
          fillColor="#B2B5B7"
          width={DEFAULT_FAVORITE_SVG_WIDTH}
          height={DEFAULT_FAVORITE_SVG_HEIGHT}
          strokeWidth={isDreamHub ? '2' : '1'}
          {...dreamHubProps}
        />
      )}
    </Button>
  )
}

export default FavoriteCircleButton
