import React, { ChangeEvent, FunctionComponent } from 'react'
import clsx from 'clsx'
import Button from '../Button'
import NewSessionIcon from '../Icons/NewSessionIcon'
import PersonCircle from '../Icons/PersonCircle'
import IUserSectionProps from './interfaces/userSection'
import initNewSessionHandler from './utils/initNewSessionHandler'
import styles from './horizontalBar.module.scss'

const UserSection: FunctionComponent<IUserSectionProps> = ({
  profileDetails,
  handleAddProfileClick,
  handleProfileClick,
  handleNewSessionClick,
  profileLinkProps,
  addProfileButtonProps
}) => {
  const { firstName = '', lastName = '', Id } = profileDetails || {}

  return (
    <>
      <a
        href="javascript: undefined"
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => initNewSessionHandler(e, handleNewSessionClick)}
        className={clsx(styles.userSection, styles.newSessionButton)}
        data-testid="new-session-button"
        id="header_new_button"
      >
        <div className={styles.newSession} />
        <NewSessionIcon fillColor="#1b244d" />
      </a>
      {Id ? (
        <Button
          className={styles.userSection}
          href="javascript: undefined"
          onClick={(e: ChangeEvent<HTMLInputElement>) => {
            const onComplete = () => {
              window.location.href = '/profile'
            }
            if (handleProfileClick) {
              handleProfileClick(e, onComplete)
            } else {
              onComplete()
            }
          }}
          {...profileLinkProps}
        >
          <div className={styles.name}>{`${firstName} ${lastName}`.trim()}</div>
          <PersonCircle />
        </Button>
      ) : (
        <Button
          className={styles.addCustomerButton}
          onClick={handleAddProfileClick}
          {...addProfileButtonProps}
          id="header_save_profile_button"
        >
          <div className={clsx(styles.addCustomerTextLandSacape, styles.addCustomerText)}>
            {addProfileButtonProps?.label}
          </div>
          <div className={clsx(styles.addCustomerTextPortrait, styles.addCustomerText)}>
            {addProfileButtonProps?.label.split(' ')[0]}
          </div>
          <PersonCircle />
        </Button>
      )}
    </>
  )
}

export default UserSection
